import React from "react";
import PropTypes from "prop-types";

// bootstrap components
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import { GeoAlt, Grid } from "react-bootstrap-icons";

// css and other assets

// local components and utils

function ViewSwitch({
  activeView,
  setActiveView,
}) {
  return (
    <ButtonGroup aria-label="Select View">
      <Button
        variant={activeView === "grid" ? "primary" : "secondary"}
        className="d-inline-flex align-items-center"
        onClick={() => setActiveView("grid")}
      >
        <Grid />
        <span className="ms-1">Grid</span>
      </Button>
      <Button
        variant={activeView === "map" ? "primary" : "secondary"}
        className="d-inline-flex align-items-center"
        onClick={() => setActiveView("map")}
      >
        <GeoAlt />
        <span className="ms-1">Map</span>
      </Button>
    </ButtonGroup>
  );
}

ViewSwitch.propTypes = {
  activeView: PropTypes.oneOf(["grid", "map"]),
  setActiveView: PropTypes.func.isRequired,
};

ViewSwitch.defaultProps = {
  activeView: "grid",
};

export default ViewSwitch;
