import React, { useState } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Dropdown from "react-bootstrap/Dropdown";

// css and other assets
import "./genus-menu.css";

// local components and utils
import { useRequests } from "../../hooks/user-context";
import Modal from "../../components/modal";
import AdvancedDataLoader from "../../components/advanced-data-loader";

function GenusMenu({ observationId, onSpeciesConfirm }) {
  const requests = useRequests();
  const [fetchingError, setFetchingError] = useState(null);
  const [genusData, setGenusData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // methods
  const loadData = (filterName, pageNum) => requests.getGenusList(filterName, pageNum);
  const onModalShow = () => { setShowModal(true); };
  const onModalHide = () => { setShowModal(false); };
  const confirm = () => {
    requests.createExpertClassification(observationId, null, selectedRecord.genusId)
      .then(() => onSpeciesConfirm())
      .catch(() => setFetchingError("Unexpected error"));
    onModalHide();
  };

  // post-process genus data
  const totalPages = (genusData != null) ? genusData.total_pages : null;
  const genusList = (genusData != null) ? genusData.genus_list : null;

  return (
    <AdvancedDataLoader
      hasData={genusList != null}
      setData={setGenusData}
      loadData={loadData}
      totalPages={totalPages}
      fetchingError={fetchingError}
      noDataMessage="No species"
      noSpacing
    >
      {genusData == null ? null : (
        <>
          <ul className="list-unstyled cf-genus-menu">
            {genusList.map((item) => (
              <Dropdown.Item
                key={item.genus_id}
                eventKey={item.genus_id}
                as="div"
                className="cf-genus-menu-item"
                onClick={() => {
                  setSelectedRecord({ genusId: item.genus_id, genusName: item.genus_name });
                  onModalShow();
                }}
              >
                <div className="cf-genus-menu-item">
                  <span><b>{item.genus_name}</b></span>
                </div>
              </Dropdown.Item>
            ))}
          </ul>
          {selectedRecord == null ? null : (
            <Modal
              show={showModal}
              onHide={onModalHide}
              onConfirm={confirm}
              title={`Confirm Genus for Observation #${observationId}`}
              body={`
              This action will create Expert Classification 
              with genus "${selectedRecord.genusName}".
              `}
            />
          )}
        </>
      )}
    </AdvancedDataLoader>
  );
}

GenusMenu.propTypes = {
  observationId: PropTypes.number.isRequired,
  onSpeciesConfirm: PropTypes.func.isRequired,
};

GenusMenu.defaultProps = {};

export default GenusMenu;
