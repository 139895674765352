// local components and utils
import Config from "../config";

export function isSuccess(status) {
  return status >= 200 && status <= 299;
}

function createHeaders(authorization = null, language = null, isJson = false) {
  const headers = new Headers({
    "X-API-Key": Config.API_KEY,
  });
  if (isJson) headers.append("Content-Type", "application/json");
  if (authorization != null) headers.append("Authorization", authorization);
  if (language != null) headers.append("Accept-Language", language);
  return headers;
}

function post(endpoint, data = {}, authorization = null, language = null) {
  return fetch(`${Config.BASE_URL}/${endpoint}`, {
    method: "POST",
    headers: createHeaders(authorization, language, true),
    body: JSON.stringify(data),
  });
}

function postFile(endpoint, data, authorization = null, language = null) {
  return fetch(`${Config.BASE_URL}/${endpoint}`, {
    method: "POST",
    headers: createHeaders(authorization, language, false),
    body: data,
  });
}

function put(endpoint, data = {}, authorization = null, language = null) {
  return fetch(`${Config.BASE_URL}/${endpoint}`, {
    method: "PUT",
    headers: createHeaders(authorization, language, true),
    body: JSON.stringify(data),
  });
}

function get(endpoint, queryParams = {}, authorization = null, language = null) {
  return fetch(`${Config.BASE_URL}/${endpoint}?${new URLSearchParams(queryParams)}`, {
    method: "GET",
    headers: createHeaders(authorization, language, false),
  });
}

export default class Requests {
  constructor(userContext, onLogout) {
    if (typeof userContext === "object") {
      this.authorization = `Bearer ${userContext.token}`;
      this.language = userContext.language;
    } else {
      this.authorization = null;
      this.language = null;
    }
    this.onLogout = onLogout;
  }

  _checkUser() {
    if (typeof this.onLogout === "function") {
      this.currentUser()
        .then(async (response) => {
          if (response.status === 200) {
            const data = await response.json();
            if (data.is_active !== true) {
              this.onLogout();
            }
          } else {
            this.onLogout();
          }
        });
    }
  }

  login(username, password) {
    return post("auth/jwt/login", { username, password }, null, this.language);
  }

  currentUser() {
    return get("user/me", {}, this.authorization, this.language);
  }

  getHabitatList() {
    return get("metadata/habitat", {}, this.authorization, this.language)
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  getSubstrateList() {
    return get("metadata/substrate", {}, this.authorization, this.language)
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  getSpeciesList(filterName = null, pageNum = 0) {
    const queryParams = { limit: 50, page_num: pageNum };
    if (filterName !== null) queryParams.filter_name = filterName;
    return get("species", queryParams, this.authorization, this.language)
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  getGenusList(filterName = null, pageNum = 0) {
    const queryParams = { limit: 200, page_num: pageNum };
    if (filterName !== null) queryParams.filter_name = filterName;
    return get("genus", queryParams, this.authorization, this.language)
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  getObservation(observationId) {
    return get(`observation/explore/${observationId}`, {}, this.authorization, this.language)
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  getObservationList(
    authorId = null,
    speciesName = null,
    pageNum = 0,
    allRecords = false,
    allSaved = false,
  ) {
    const queryParams = { to_verify: true, limit: allRecords ? -1 : 50, page_num: pageNum };
    if (allSaved) {
      queryParams.to_verify = false;
      queryParams.all_saved = true;
    }
    if (authorId !== null) queryParams.author_id = authorId;
    if (speciesName !== null) queryParams.species_name = speciesName;
    return get("observation/explore", queryParams, this.authorization, this.language)
      .then(async (response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  getSavedObservationList(speciesName = null, pageNum = 0, allRecords = false) {
    const queryParams = { limit: allRecords ? -1 : 50, page_num: pageNum };
    if (speciesName !== null) queryParams.species_name = speciesName;
    return get("observation", queryParams, this.authorization, this.language)
      .then(async (response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  createObservationWithImage(image) {
    const formData = new FormData();
    formData.append("image", image);
    return postFile(
      "observation/image",
      formData,
      this.authorization,
      this.language,
    )
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  createObservationImage(observationId, image) {
    const formData = new FormData();
    formData.append("image", image);
    return postFile(
      `observation/${observationId}/image`,
      formData,
      this.authorization,
      this.language,
    )
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  updateObservation(observationId, habitat, substrate, date) {
    return put(
      `observation/${observationId}`,
      { metadata: { habitat, substrate, date } },
      this.authorization,
      this.language,
    )
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  getObservationPrediction(observationId) {
    return get(
      `observation/${observationId}/prediction`,
      {},
      this.authorization,
      this.language,
    )
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  createAuthorClassification(observationId, speciesId) {
    return post(
      `observation/${observationId}/author_classification`,
      { species_id: speciesId },
      this.authorization,
      this.language,
    )
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  createExpertClassification(observationId, speciesId, genusId) {
    return post(
      `observation/${observationId}/expert_classification`,
      { species_id: speciesId, genus_id: genusId },
      this.authorization,
      this.language,
    )
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  createObservationComment(observationId, authorId, message) {
    return post(
      `observation/${observationId}/comment`,
      { message },
      this.authorization,
      this.language,
    )
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  saveObservation(observationId) {
    return post(`observation/${observationId}/save`, null, this.authorization, this.language)
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }

  createObservationExport() {
    return post("observation/export", null, this.authorization, this.language)
      .then((response) => {
        if (!isSuccess(response.status)) this._checkUser();
        return response;
      });
  }
}
