import React, { forwardRef } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Dropdown from "react-bootstrap/Dropdown";

// css and other assets
import "./location-dropdown.css";

// local components and utils
import Map, { Marker } from "../../components/map";

// use forwardRef to allow Dropdown to access the DOM of the Menu to measure it
const DropdownMenu = forwardRef(({
  latitude, longitude, className, style,
}, ref) => (
  <div
    ref={ref}
    className={`${className} cf-location-dropdown`.trim()}
    style={style}
  >
    <div className="cf-location-dropdown-inner">
      <Map
        center={[latitude, longitude]}
        zoom={9}
        height={500}
        width={500}
      >
        <Marker position={[latitude, longitude]} />
      </Map>
    </div>
  </div>
));

DropdownMenu.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

function LocationDropdown({ latitude, longitude }) {
  return (
    <Dropdown drop="up">
      <Dropdown.Toggle
        id="dropdown-location"
        variant="primary"
        size="sm"
        className="cf-location-dropdown-button"
      >
        View Location
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropdownMenu}
        latitude={latitude}
        longitude={longitude}
      />
    </Dropdown>
  );
}

LocationDropdown.propTypes = {
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
};

export default LocationDropdown;
