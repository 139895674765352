import React, { useEffect, useState } from "react";

// bootstrap components
import Stack from "react-bootstrap/Stack";
import Alert from "react-bootstrap/Alert";

// css and other assets

// local components and utils
import PropTypes from "prop-types";
import Paginator from "./paginator";
import DataLoader from "./data-loader";
import SearchBar from "./search-bar";

function AdvancedDataLoader({
  hasData,
  setData,
  loadData,
  totalPages,
  fetchingError,
  noDataMessage,
  useSingleFetch,
  noSpacing,
  children,
}) {
  const [cachedData, setCachedData] = useState({});
  const [filterName, setFilterName] = useState(null);
  const [pageNum, setPageNum] = useState(0);

  useEffect(() => {
    setCachedData({});
    setPageNum(0);
  }, [useSingleFetch]);

  const loadCachedData = () => new Promise((resolve) => {
    const response = {
      status: 200,
      loadedData: cachedData[pageNum],
      json: () => Promise.resolve(cachedData[pageNum]),
    };
    resolve(response);
  });

  const _loadData = () => ((cachedData[pageNum] != null && !useSingleFetch)
    ? loadCachedData() : loadData(filterName, pageNum));
  const _setData = (newData) => {
    setData(newData);
    const newCachedData = { ...cachedData };
    newCachedData[pageNum] = newData;
    setCachedData(newCachedData);
  };

  let body = null;
  if (hasData) {
    body = (
      <>
        {children}
        {(totalPages === 0) ? (
          <Alert variant="info">{noDataMessage}</Alert>
        ) : null}
        {(totalPages > 0 && !useSingleFetch) ? (
          <Paginator
            pageNum={pageNum}
            totalPages={totalPages}
            onClick={(i) => setPageNum(i - 1)}
          />
        ) : null}
      </>
    );
    if (!noSpacing) {
      body = (<Stack gap={4} className="align-items-center mb-3">{body}</Stack>);
    }
  }

  return (
    <>
      <SearchBar
        value={filterName}
        onChange={(e) => {
          setFilterName(e.target.value);
          setPageNum(0);
          setCachedData({}); // reset cached data
        }}
      />
      <DataLoader
        loadData={_loadData}
        setData={_setData}
        effectDependencies={[filterName, pageNum, useSingleFetch]}
        fetchingError={fetchingError}
      >
        {body}
      </DataLoader>
    </>
  );
}

AdvancedDataLoader.propTypes = {
  hasData: PropTypes.bool.isRequired,
  setData: PropTypes.func.isRequired,
  loadData: PropTypes.func.isRequired,
  totalPages: PropTypes.number,
  fetchingError: PropTypes.string,
  noDataMessage: PropTypes.string,
  useSingleFetch: PropTypes.bool,
  noSpacing: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

AdvancedDataLoader.defaultProps = {
  totalPages: null,
  fetchingError: null,
  noDataMessage: "No data found",
  useSingleFetch: false,
  noSpacing: false,
  children: null,
};

export default AdvancedDataLoader;
