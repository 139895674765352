import React, { useState } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Image from "react-bootstrap/Image";
import Dropdown from "react-bootstrap/Dropdown";
import Badge from "react-bootstrap/Badge";

// css and other assets
import "./species-menu.css";

// local components and utils
import { useRequests } from "../../hooks/user-context";
import Modal from "../../components/modal";
import AdvancedDataLoader from "../../components/advanced-data-loader";

function SpeciesMenuItem({
  speciesId, speciesName, commonName, imageUrl, toxicityStatus, onClick,
}) {
  let badgeColor;
  let badgeText;
  switch (toxicityStatus) {
  case "edible":
    badgeColor = "success";
    badgeText = "Edible";
    break;
  case "non_edible":
    badgeColor = "warning";
    badgeText = "Non-edible";
    break;
  case "poisonous":
    badgeColor = "danger";
    badgeText = "Poisonous";
    break;
  default:
    badgeColor = "success";
    badgeText = "Edible";
  }
  return (
    <Dropdown.Item
      eventKey={speciesId}
      as="div"
      className="cf-species-menu-item"
      onClick={() => typeof onClick === "function" && onClick()}
    >
      <Image rounded src={imageUrl} className="cf-species-menu-item-image" />
      <div className="cf-species-menu-item-text">
        <span><b>{speciesName}</b></span>
        {commonName == null ? null : <span className="text-primary">{commonName}</span>}
        {toxicityStatus == null ? null : <Badge bg={badgeColor}>{badgeText}</Badge>}
      </div>
    </Dropdown.Item>
  );
}

SpeciesMenuItem.propTypes = {
  speciesId: PropTypes.number.isRequired,
  speciesName: PropTypes.string.isRequired,
  commonName: PropTypes.string,
  imageUrl: PropTypes.string,
  toxicityStatus: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

SpeciesMenuItem.defaultProps = {
  commonName: null,
  imageUrl: null,
  toxicityStatus: null,
};

function SpeciesMenu({ observationId, onSpeciesConfirm }) {
  const requests = useRequests();
  const [fetchingError, setFetchingError] = useState(null);
  const [speciesData, setSpeciesData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);

  // methods
  const loadData = (filterName, pageNum) => requests.getSpeciesList(filterName, pageNum);
  const onModalShow = () => { setShowModal(true); };
  const onModalHide = () => { setShowModal(false); };
  const confirm = () => {
    requests.createExpertClassification(observationId, selectedRecord.speciesId, null)
      .then(() => onSpeciesConfirm())
      .catch(() => setFetchingError("Unexpected error"));
    onModalHide();
  };

  // post-process species data
  const totalPages = (speciesData != null) ? speciesData.total_pages : null;
  const speciesList = (speciesData != null) ? speciesData.species_list : null;

  return (
    <AdvancedDataLoader
      hasData={speciesData != null}
      setData={setSpeciesData}
      loadData={loadData}
      totalPages={totalPages}
      fetchingError={fetchingError}
      noDataMessage="No species"
      noSpacing
    >
      {speciesData == null ? null : (
        <>
          <ul className="list-unstyled cf-species-menu">
            {speciesList.map((item) => (
              <SpeciesMenuItem
                key={item.species_id}
                speciesId={item.species_id}
                speciesName={item.species_name}
                commonName={item.common_name}
                imageUrl={item.image_url}
                toxicityStatus={item.toxicity_status}
                onClick={() => {
                  setSelectedRecord({ speciesId: item.species_id, speciesName: item.species_name });
                  onModalShow();
                }}
              />
            ))}
          </ul>
          {selectedRecord == null ? null : (
            <Modal
              show={showModal}
              onHide={onModalHide}
              onConfirm={confirm}
              title={`Confirm Species for Observation #${observationId}`}
              body={`
              This action will create Expert Classification 
              with species "${selectedRecord.speciesName}".
              `}
            />
          )}
        </>
      )}
    </AdvancedDataLoader>
  );
}

SpeciesMenu.propTypes = {
  observationId: PropTypes.number.isRequired,
  onSpeciesConfirm: PropTypes.func.isRequired,
};

SpeciesMenu.defaultProps = {};

export default SpeciesMenu;
