import React, { useState } from "react";

// bootstrap components
import Stack from "react-bootstrap/Stack";

// css and other assets
import "./index.css";

// local components and utils
import { useRequests } from "../../hooks/user-context";
import ObservationCard from "../../data-components/observation-card";
import CardList from "../../components/card-list";
import AdvancedDataLoader from "../../components/advanced-data-loader";
import ViewSwitch from "../../components/view-switch";
import Map, { Marker } from "../../components/map";

function ExpertVerification() {
  const requests = useRequests();
  const [observationData, setObservationData] = useState(null);
  const [activeView, setActiveView] = useState("grid");

  // methods
  const loadData = (filterName, pageNum) => {
    const allRecords = activeView === "map";
    return requests.getObservationList(null, filterName, pageNum, allRecords);
  };

  // post-process observation data
  const totalPages = (observationData != null) ? observationData.total_pages : null;
  const observationList = (observationData != null) ? observationData.observation_list : null;

  return (
    <div className="cf-expert-verification">
      <AdvancedDataLoader
        hasData={observationData != null}
        setData={setObservationData}
        loadData={loadData}
        totalPages={totalPages}
        noDataMessage="No observations"
        useSingleFetch={activeView === "map"}
      >
        <Stack direction="horizontal" className="align-items-start">
          <ViewSwitch activeView={activeView} setActiveView={setActiveView} />
        </Stack>
        {/* eslint-disable-next-line no-nested-ternary */}
        {observationData == null ? null : (
          activeView === "grid" ? (
            <CardList>
              {observationList.map((item) => (
                <ObservationCard
                  key={item.observation_id}
                  observationId={item.observation_id}
                  dateUpdated={item.date_updated}
                  images={item.images}
                  metadata={item.metadata}
                  classification={item.classification}
                  comments={item.comments}
                  username={item.username}
                />
              ))}
            </CardList>
          ) : (
            <Map
              center={[50.08804000, 14.42076000]}
              zoom={6}
              width="90vw"
              height="90vh"
            >
              {observationList
                .filter((item) => item.metadata.latitude != null && item.metadata.longitude != null)
                .map((item) => (
                  <Marker
                    key={item.observation_id}
                    position={[item.metadata.latitude, item.metadata.longitude]}
                    popup={(
                      <ObservationCard
                        observationId={item.observation_id}
                        dateUpdated={item.date_updated}
                        images={item.images}
                        metadata={item.metadata}
                        classification={item.classification}
                        comments={item.comments}
                        username={item.username}
                      />
                    )}
                    popupProps={{ maxWidth: 560 }}
                  />
                ))}
            </Map>
          )
        )}
      </AdvancedDataLoader>
    </div>
  );
}

export default ExpertVerification;
