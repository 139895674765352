import React, { useState, forwardRef } from "react";
import PropTypes from "prop-types";

// bootstrap components
import Dropdown from "react-bootstrap/Dropdown";
import Nav from "react-bootstrap/Nav";

// css and other assets
import "./species-dropdown.css";

// local components and utils
import SpeciesMenu from "./species-menu";
import GenusMenu from "./genus-menu";

// use forwardRef to allow Dropdown to access the DOM of the Menu to measure it
const DropdownMenu = forwardRef(({
  observationId, onSpeciesConfirm, className, style,
}, ref) => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div
      ref={ref}
      className={`${className} cf-species-dropdown`.trim()}
      style={style}
    >
      <div className="cf-species-dropdown-inner">
        <Nav variant="tabs">
          <Nav.Item>
            <Nav.Link
              active={activeTab === 0}
              onClick={() => setActiveTab(0)}
            >
              Select Species
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              active={activeTab === 1}
              onClick={() => setActiveTab(1)}
            >
              Select Genus
            </Nav.Link>
          </Nav.Item>
        </Nav>
        {activeTab === 0 ? (
          <SpeciesMenu observationId={observationId} onSpeciesConfirm={onSpeciesConfirm} />
        ) : (
          <GenusMenu observationId={observationId} onSpeciesConfirm={onSpeciesConfirm} />
        )}
      </div>
    </div>
  );
});

DropdownMenu.propTypes = {
  observationId: PropTypes.number.isRequired,
  onSpeciesConfirm: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  style: PropTypes.object.isRequired,
};

function SpeciesDropdown({ observationId, onSpeciesConfirm }) {
  return (
    <Dropdown drop="up">
      <Dropdown.Toggle
        id="dropdown-species-select"
        variant="primary"
        size="sm"
        className="cf-species-dropdown-button"
      >
        Select Species
      </Dropdown.Toggle>
      <Dropdown.Menu
        as={DropdownMenu}
        observationId={observationId}
        onSpeciesConfirm={onSpeciesConfirm}
      />
    </Dropdown>
  );
}

SpeciesDropdown.propTypes = {
  observationId: PropTypes.number.isRequired,
  onSpeciesConfirm: PropTypes.func.isRequired,
};

export default SpeciesDropdown;
