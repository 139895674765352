import React from "react";
import PropTypes from "prop-types";

// bootstrap components

// css and other assets

// local components and utils

function CardList({ children }) {
  return (
    <div className="d-flex flex-row justify-content-center align-items-center flex-wrap">
      {children}
    </div>
  );
}

CardList.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

CardList.defaultProps = {
  children: null,
};

export default CardList;
