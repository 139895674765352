import React from "react";
import PropTypes from "prop-types";

// bootstrap components
import Pagination from "react-bootstrap/Pagination";

// css and other assets
import "./container.css";

function PaginatorItem({ active, i, onClick }) {
  return (
    <Pagination.Item active={active === i} onClick={() => onClick(i)}>{i}</Pagination.Item>
  );
}

PaginatorItem.propTypes = {
  active: PropTypes.number.isRequired,
  i: PropTypes.number.isRequired,
  onClick: PropTypes.func.isRequired,
};

function Paginator({
  pageNum, totalPages, onClick, className,
}) {
  const active = pageNum + 1;
  const _onClick = (i) => {
    if (typeof onClick === "function") onClick(i);
  };

  // pagination design
  // show at most 7 numbers
  // always show first and last
  const items = [];
  if (totalPages <= 8) {
    for (let i = 1; i <= totalPages; i++) {
      items.push(<PaginatorItem key={i} active={active} i={i} onClick={_onClick} />);
    }
  } else {
    items.push(<PaginatorItem key={1} active={active} i={1} onClick={_onClick} />);
    if (active <= 4) {
      // 1 2 3 (4) 5 6 ... 9
      for (let i = 2; i <= 6; i++) {
        items.push(<PaginatorItem key={i} active={active} i={i} onClick={_onClick} />);
      }
      items.push(<Pagination.Ellipsis key="ellipsis-1" disabled />);
    } else if (active >= totalPages - 4) {
      // 1 ... 4 5 6 (7) 8 9
      items.push(<Pagination.Ellipsis key="ellipsis-1" disabled />);
      for (let i = totalPages - 6; i <= totalPages - 1; i++) {
        items.push(<PaginatorItem key={i} active={active} i={i} onClick={_onClick} />);
      }
    } else {
      // 1 ... 3 4 (5) 6 7 ... 9
      items.push(<Pagination.Ellipsis key="ellipsis-1" disabled />);
      for (let i = active - 2; i <= active + 2; i++) {
        items.push(<PaginatorItem key={i} active={active} i={i} onClick={_onClick} />);
      }
      items.push(<Pagination.Ellipsis key="ellipsis-2" disabled />);
    }
    items.push(
      <PaginatorItem key={totalPages} active={active} i={totalPages} onClick={_onClick} />,
    );
  }

  return (
    <Pagination className={className}>
      <Pagination.Prev onClick={() => _onClick(active - 1)} disabled={active === 1} />
      { items }
      <Pagination.Next onClick={() => _onClick(active + 1)} disabled={active === totalPages} />
    </Pagination>
  );
}

Paginator.propTypes = {
  pageNum: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

Paginator.defaultProps = {
  onClick: null,
  className: "",
};

export default Paginator;
