import React from "react";
import PropTypes from "prop-types";
import L from "leaflet";
import {
  MapContainer, TileLayer, Marker as LeafletMarker, Popup,
} from "react-leaflet";

// bootstrap components

// css and other assets
import "leaflet/dist/leaflet.css";

// local components and utils

export const markerIcon = L.divIcon({
  html: `
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 16 16"
  width="3em"
  height="3em"
  color="red"
  fill="currentColor"
>
  <path
    d="M8 16s6-5.686 6-10A6 6 0 0 0 2 6c0 4.314 6 10 6 10zm0-7a3 3 0 1 1 0-6 3 3 0 0 1 0 6z"
  />
</svg>`,
  className: "",
  iconSize: [24, 40],
  iconAnchor: [12, 40],
});

export function Marker({
  position,
  popup,
  popupProps,
}) {
  return (
    <LeafletMarker
      position={position}
      icon={markerIcon}
    >
      {popup == null ? null : (<Popup {...popupProps}>{popup}</Popup>)}
    </LeafletMarker>
  );
}

Marker.propTypes = {
  position: PropTypes.array.isRequired,
  popup: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
  popupProps: PropTypes.object,
};

Marker.defaultProps = {
  popup: null,
  popupProps: {},
};

function Map({
  center,
  zoom,
  height,
  width,
  children,
}) {
  return (
    <MapContainer
      center={center}
      zoom={zoom}
      scrollWheelZoom={false}
      style={{ height, width }}
    >
      <TileLayer
        // eslint-disable-next-line max-len
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {children}
    </MapContainer>
  );
}

Map.propTypes = {
  center: PropTypes.array,
  zoom: PropTypes.number,
  height: PropTypes.number,
  width: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Map.defaultProps = {
  center: [50.08804000, 14.42076000],
  zoom: 6,
  height: 500,
  width: 500,
  children: null,
};

export default Map;
